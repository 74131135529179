/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { atualizarSaldo } from '../../../store/modules/parceiro/actions';
import api from '../../../services/API';
import './styles.css';
import { Link } from 'react-router-dom';
import Pagination from 'rc-pagination';

export default function PremiosLoja(props) {
  const [premios, setPremios] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagina, setPagina] = useState(1);
  const [limite, setLimite] = useState(48);
  const [carregando, setCarregando] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [categoriaId, setCategoriaId] = useState('');
  const [showSubCategoria, setShowSubCategoria] = useState(false);
  const [busca, setBusca] = useState('');
  const [categoriaNome, setCategoriaNome] = useState('');
  const [categoriaLevel, setCategoriaLevel] = useState('');
  const [categoriaSelecionada, setCategoriaSelecionada] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);
  const [subCategoriaSelecionada, setSubCategoriaSelecionada] = useState([]);

  const dispatch = useDispatch();
  const parceiroRedux = useSelector((state) => state.parceiro);

  const buscarPremios = async (categoria, subCategoria, level) => {
    try {
      setCarregando(true);
      
      const params = {
        pagina,
        limite,
        ativo: 1
      };

      if (categoria) {
        params.codigoCategoria = categoria;
      }
      
      if (subCategoria) {
        params.codigoSubCategoria = subCategoria;
        params.levelCategoria = level;
        console.log('Buscando com subcategoria:', {
          categoria,
          subCategoria,
          level
        });
      }

      console.log('Chamando API com params:', params);
      const resultado = await api.get('/api/premios', { params });
      
      if (resultado?.data?.premios) {
        setPremios(resultado.data.premios);
        setTotal(resultado.data.total);
      } else {
        setPremios([]);
        setTotal(0);
      }
    } catch (error) {
      console.error('Erro ao buscar prêmios:', error);
      setPremios([]);
      setTotal(0);
    } finally {
      setCarregando(false);
    }
  };

  const buscarCategorias = async () => {
    try {
      const response = await api.get('/api/premios/categorias?level=1');
      if (response?.data?.categorias) {
        setCategorias(response.data.categorias);
      }
    } catch (error) {
      console.error('Erro ao buscar categorias:', error);
      setCategorias([]);
    }
  };

  const showDropdown = () => {
    document.getElementById("myDropdown").classList.toggle("show");
  };

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick(e);
    }
  };

  const handleSearchClick = async (e) => {
    e.preventDefault();
    setPagina(1);
    await buscarPremios(categoriaId);
  };

  const buscarSubCategorias = async (categoriaId) => {
    try {
      const response = await api.get(`/api/premios/categorias?level=2&idCategoriaPai=${categoriaId}`);
      if (response?.data?.categorias) {
        setSubCategorias(response.data.categorias);
        setShowSubCategoria(true);
      }
    } catch (error) {
      console.error('Erro ao buscar subcategorias:', error);
      setSubCategorias([]);
      setShowSubCategoria(false);
    }
  };

  // Efeito inicial
  useEffect(() => {
    const inicializarDados = async () => {
      try {
        await buscarCategorias();
        await buscarPremios();
      } catch (error) {
        console.error('Erro ao inicializar dados:', error);
      }
    };

    inicializarDados();
  }, []);

  // Efeito para mudanças na paginação ou categoria
  useEffect(() => {
    const atualizarDados = async () => {
      if (categoriaId) {
        await buscarSubCategorias(categoriaId);
      } else {
        setShowSubCategoria(false);
        setSubCategorias([]);
      }
      await buscarPremios(categoriaId);
      dispatch(atualizarSaldo(parceiroRedux.cpf));
    };

    atualizarDados();
  }, [pagina, limite, categoriaId]);

  return (
    <Container className="mb-5">
      <div className="mt-3 align-self-start justify-content-center sticky-search">
        <div id="container-busca-geral">
          <div className="d-flex flex-column justify-content-between align-items-center container-busca">
            <h5 className="mb-3">BUSCAR PRODUTO</h5>
            <div className="d-flex flex-row align-items-center justify-content-start align-self-start mb-4 w-100 container-secaoDropdown">
              <div className="secaoDropdown w-100">
                <input
                  value={busca}
                  onChange={(e) => setBusca(e.target.value)}
                  onKeyUp={handleSearch}
                  className="input-busca"
                  placeholder="Digite a palavra chave"
                />
                <button
                  className="btn-pesquisa mt-0"
                  type="submit"
                  onClick={(e) => {
                    setCategoriaId(null);
                    handleSearchClick(e);
                  }}
                >
                  BUSCAR
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between align-items-center container-busca-dois">
            <h5 className="mb-3">NAVEGUE POR CATEGORIA</h5>
            <div className="w-100 container-secaoDropdown-dois">
              <div className="secaoDropdown-dois w-100">
                <div className="dropdown">
                  <button
                    onClick={() => {
                      setBusca('');
                      showDropdown();
                    }}
                    className="dropbtn"
                  >
                    {categoriaId ? categoriaNome : 'Escolha uma categoria'}
                    <i className="fas fa-chevron-down"></i>
                  </button>
                  <div id="myDropdown" className="dropdown-content">
                    <button
                      onClick={(e) => {
                        setBusca(null);
                        setCategoriaSelecionada([]);
                        setCategoriaId(null);
                        setCategoriaLevel(null);
                        setCategoriaNome(null);
                        handleSearchClick(e);
                      }}
                      value={''}
                    >
                      Todas
                    </button>
                    {categorias.map((item) => {
                      return (
                        <button
                          onClick={(e) => {
                            setCategoriaSelecionada([]);
                            setCategoriaId(item.idCategoria);
                            setCategoriaLevel(item.level);
                            setCategoriaNome(item.nome);
                            setCategoriaSelecionada([
                              item.idCategoria,
                              item.level,
                              item.nome,
                            ]);
                            setPagina(1);
                            handleSearchClick(e);
                          }}
                          key={item.id}
                          value={item.idCategoria}
                        >
                          {item.nome}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <hr className="hr-loja mt-0" />
          </div>
        </div>
        <hr className="hr-loja-desktop mt-0" />
      </div>
      <Row>
        {!carregando && showSubCategoria && (
          <Col lg={3}>
            <div id="sidebar">
              <div>
                <h6>Sub-categorias</h6>
                <div className="conteudo-subcategoria">
                  {subCategorias.map((item) => {
                    return (
                      <button
                        onClick={() => {
                          const subCategoria = item.idCategoria;
                          const level = item.level;
                          setSubCategoriaSelecionada(item);
                          console.log('Selecionando subcategoria:', {
                            categoriaId,
                            subCategoria,
                            level
                          });
                          buscarPremios(categoriaId, subCategoria, level);
                        }}
                        key={item.id}
                      >
                        {item.nome}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </Col>
        )}
        {!carregando && showSubCategoria && (
          <Col xs={12} className="container-sidenav">
            <span className="openFiltro" onClick={() => openNav()}>
              Filtros
            </span>
            <div id="mySidenav" class="sidenav">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={() => closeNav()}
              >
                &times;
              </a>
              <h6>Sub-categorias</h6>
              <div className="conteudo-subcategoria-mobile">
                {subCategorias.map((item) => {
                  return (
                    <button
                      onClick={() => {
                        const subCategoria = item.idCategoria;
                        const level = item.level;
                        buscarPremios(categoriaId, subCategoria, level);
                      }}
                      key={item.id}
                    >
                      {item.nome}
                    </button>
                  );
                })}
              </div>
            </div>
          </Col>
        )}
        {carregando ? (
          <Col xs={12}>
            <h6>Carregando...</h6>
          </Col>
        ) : premios.length === 0 ? (
          <Col xs={12}>
            <h6>Nenhum produto encontrado.</h6>
          </Col>
        ) : (
          <Col>
            <Row>
              {premios.map((premio) => {
                return (
                  <Col
                    key={premio.id}
                    id={premio.id}
                    name="premio"
                    xs={12}
                    md={4}
                    lg={3}
                    className="mt-5"
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                      <Link to={`/premio/${premio.id}`}>
                        <img
                          alt={premio.nome}
                          className="w-100"
                          src={premio.diretorioFoto}
                        ></img>
                      </Link>

                      <span className="card-premio-nome">{premio.nome}</span>
                      <span>
                        <b>
                          {Number(premio?.pontos).toLocaleString('pt-BR')}
                          {` pontos`}
                        </b>
                      </span>
                      <Link to={`/premio/${premio.id}`}>
                        <button className="btn-resgatar mt-1">RESGATAR</button>
                      </Link>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        )}
      </Row>
      <Row className="mt-5">
        <Col xs={12} className="d-flex justify-content-center">
          <Pagination
            current={pagina}
            showSizeChanger={true}
            total={total}
            pageSize={limite}
            hideOnSinglePage
            locale="pt_BR"
            onChange={(current, pageSize) => setPagina(current)}
            onShowSizeChange={(current, size) => setLimite(size)}
          />
        </Col>
      </Row>
    </Container>
  );
}
